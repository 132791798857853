<template>
  <div class="jobFairIntroduction">
    <header>
      <img src="~/static/public/jobFair/banner.png" alt="">
    </header>
    <section class="section_box one">
      <div class="section_content">
        <div class="title">
          <img src="~/static/public/jobFair/left.png" alt=""><div class="name">招聘会活动介绍</div><img src="~/static/public/jobFair/right.png" alt="">
        </div>
        <div class="text">
          为贯彻落实国务院常务会议关于做好高校毕业生等重点群体就业和全国普通高等学校毕业生就业创业工作电视电话会议精神，切实做好残疾人毕业生就业帮扶工作，实现就业服务精准化、个性化，全国高等学校学生信息咨询与就业指导中心和中国残疾人联合会就业服务指导中心于2021年5月16日-7月15日联合开展2021年高校残疾人毕业生网络招聘会, 高校毕业生及用人单位可登陆兰州市残疾人就业创业网络服务平台、教育部大学生就业网参与活动。
        </div>
      </div>
    </section>
    <section class="section_box two">
      <div class="section_content">
        <div class="title">
          <img src="~/static/public/jobFair/left.png" alt=""><div class="name">招聘会的优势</div><img src="~/static/public/jobFair/right.png" alt="">
        </div>
        <ul class="list">
          <li>
            <img src="~/static/public/jobFair/pic1.png" alt="">
            <h3>优质人力资源</h3>
            <div class="texts">
              本次招聘会，主办方将推动应届及往届高校残疾人毕业生参会，将提供丰富优质的残疾人力资源。
            </div>
          </li>
          <li>
            <img src="~/static/public/jobFair/pic3.png" alt="">
            <h3>享受补贴政策</h3>
            <div class="texts">
              可享受按比例安置、超比例安置以及安置残疾人大学生专项补贴以及免税政策。
            </div>
          </li>
          <li>
            <img src="~/static/public/jobFair/pic.png" alt="">
            <h3>全流程服务</h3>
            <div class="texts">
              平台服务人员、社会机构、全国各级残联将对参会企业开展招聘前、招聘中与招聘后的招聘指导、政策宣讲和人岗匹配服务。
            </div>
          </li>
          <li>
            <img src="~/static/public/jobFair/pic2.png" alt="">
            <h3>广泛社会宣传</h3>
            <div class="texts">
              安置高校残疾人毕业生就业典型企业，将获得主办方多渠道宣传，提升企业社会形象，助力企业品牌宣传。
            </div>
          </li>
        </ul>
      </div>
    </section>
    <section class="section_box three">
      <div class="section_content">
        <div class="title">
          <img src="~/static/public/jobFair/left.png" alt=""><div class="name">5分钟，便捷参加</div><img src="~/static/public/jobFair/right.png" alt="">
        </div>
        <div class="image">
          <img src="~/static/public/jobFair/1.png" alt="">
          <img src="~/static/public/jobFair/2.png" alt="">
          <img src="~/static/public/jobFair/3.png" alt="">
          <img src="~/static/public/jobFair/4.png" alt="">
          <img src="~/static/public/jobFair/5.png" alt="">
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "jobFairIntroduction"
}
</script>

<style scoped lang="less">
  .jobFairIntroduction{
    min-height:100vh;
    background:#CBDEFE;
    header{
      margin-bottom: 12px;
      img{
        width: 100%;
      }
    }
    .section_box{
      position: relative;
      width: 976px;
      margin: 88px auto 0 auto;
      border-radius: 16px;
      background: white;
      padding: 16px;
      .section_content{
        border: 2px dashed #999999;
        border-radius: 16px;
        .title{
          display: flex;
          position: absolute;
          top: -32px;
          left: 50%;
          transform: translateX(-50%);
          .name{
            display: flex;
            font-size: 24px;
            color: white;
            background: #2B6FF6;
            align-items: center;
          }
        }
        .text{
          text-indent: 2em;
          line-height: 40px;
          font-size: 18px;
          color: #666666;
          padding: 20px 32px;
        }
      }
    }
    .two{
      .list{
        display: flex;
        justify-content: space-between;
        padding: 40px 32px 20px 32px;
        li{
          width: 192px;
          text-align: center;
          h3{
            font-size: 20px;
            color: #2B6FF6;
            padding: 10px 0;
          }
          .texts{
            font-size: 16px;
            color: #666666;
            text-align: left;
            line-height: 30px;
          }
        }
      }
    }
    .three{
      .image{
        padding: 20px 0;
        img{
          margin-top: 56px;
        }
      }
    }
  }
</style>
